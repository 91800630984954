<template>
  <div>
    <loader :color="companyData.companyColor" v-if="loading" :loading="loading"/>
<div v-else class="tw-flex tw-w-full tw-flex-col tw-p-5">
  <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-between">
    <div class="tw-cursor-pointer" @click="handleSingleManifest">
      <icons  name="arrow-back" :color="companyData.companyColor" />
    </div>
    <div>
      <v-icon :color="companyData.companyColor" class="tw-mr-5" v-if="$vuetify.breakpoint.smOnly">mdi-share-variant</v-icon>
      <div class="tw-cursor-pointer pilot-label"
           @click="handlePrintManifest">Print <v-icon :color="companyData.companyColor" >mdi-file-pdf-box</v-icon></div>
    </div>
  </div>
   <manifest-content :color="companyData.companyColor" :manifest="manifest" :travellers="travellers"/>
  <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="myPDF"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a3"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"

  >
    <section slot="pdf-content">
      <manifest-content :manifest="manifest" :travellers="travellers"  :color="companyData.companyColor"/>
    </section>
  </VueHtml2pdf>

</div>
  </div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import VueHtml2pdf from 'vue-html2pdf'
import ManifestContent from "@/components/transportCompany/manifest/ManifestContent";
import {getManifestById} from "@/services/api/APIService";
import Loader from "@/components/reuseables/Loader";
export default {
  name: "SingleManifest",
  components: {Loader, ManifestContent, Icons,VueHtml2pdf},
  data(){
    return{
      companyData: {},
      manifest:{},
      travellers:[],
      loading : false
    }
  },
  methods:{
    handleSingleManifest() {
      let staffData = JSON.parse(sessionStorage.getItem("staffData"))
      this.$store.dispatch("manifestComponent/setCurrentComponent", "allManifest");
      sessionStorage.setItem('manifestComponent', "allManifest")
      if (staffData){
        if (this.$route.name !== 'AllManifestCompanyStaff')
          this.$router.push({name: 'AllManifestCompanyStaff'})
      }
      else {
        if (this.$route.name !== 'AllManifest')
          this.$router.push({name: 'AllManifest'})
      }

    },
    async handlePrintManifest(){
      this.$refs.html2Pdf.generatePdf()
    },

    async getManifestById(){
      this.loading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.manifestId = this.manifestId
      await getManifestById(data).then(res =>{
        this.manifest = res.data

        if (this.manifest.travellers){
          this.travellers = this.manifest.travellers
          this.travellers.forEach((traveller,index) => traveller.sno = index +1 )
        }
        this.loading = false
      }).catch((err) => {
        this.loading = false;
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    }
  },
  async created() {
    this.companyData = JSON.parse(sessionStorage.getItem("companyData"))
    this.manifestId = sessionStorage.getItem("manifestId")
    await this.getManifestById()
  }
}
</script>

<style  lang="scss">
.print-manifest{
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  @media print {
    @page {
      size: landscape
    }
    .orientation {
      width: 100%;
    }
  }

}

.pilot{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.pilot-label{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}
.pilot-label-content{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}
</style>