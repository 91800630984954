<template>
  <div class="tw-mt-10 tw-bg-white tw-w-full print-manifest tw-flex-col tw-px-5 lg:tw-px-20 tw-py-10">
    <img class="company-logo" :src="companyData.companyLogo" alt=""/>
    <h6 class="company-name tw-pt-8 tw-pb-8 text-capitalize" :style="{color: companyData.companyColor}">{{companyData.companyName}}</h6>
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full lg:tw-justify-between">
      <div class="tw-flex-col">
        <h6 class="pilot" :style="{color: companyData.companyColor}">
          Pilot
        </h6>

      <div class="tw-flex tw-flex-row tw-mt-10">
        <h6 class="pilot-label tw-mr-5">Name:</h6> <h6 class="pilot-label-content text-capitalize">{{manifest.pilotFirstName + " " +manifest.pilotLastName}}</h6>
      </div>
      <div class="tw-flex tw-flex-row tw-mt-10">
        <h6 class="pilot-label tw-mr-5">Mobile No:</h6> <h6 class="pilot-label-content text-capitalize">{{manifest.pilotPhoneNumber}}</h6>
      </div>
      </div>

      <div class="tw-flex-col">
        <h6 class="pilot tw-py-5 lg:tw-py-0" :style="{color: companyData.companyColor}">
          Details
        </h6>

        <div class="tw-flex tw-flex-row lg:tw-mt-10 tw-items-center ">
          <icons class="tw-mr-5" name="fleet" :color="companyData.companyColor"/>
          <h6 class="pilot-label tw-mr-5">Vehicle Reg No:</h6>
          <h6 class="pilot-label-content text-uppercase">{{manifest.trip && manifest.trip.fleetForThisTrip.vehicleRegNumber}}</h6>
        </div>
        <div class="tw-flex tw-flex-row tw-mt-10 tw-items-center">
          <v-icon :color="companyData.companyColor" class="tw-mr-5 tw-p-0">mdi-calendar-month-outline</v-icon>
          <h6 class="pilot-label tw-mr-5">Take Off Date:</h6>
          <h6 class="pilot-label-content text-capitalize" v-if="manifest.trip">{{manifest.trip && manifest.trip.takeOffDate}}</h6>
        </div>
        <div class="tw-flex tw-flex-row tw-mt-10 tw-items-center">
          <v-icon :color="companyData.companyColor" class="tw-mr-5 tw-p-0">mdi-clock-time-four-outline</v-icon>
          <h6 class="pilot-label tw-mr-5">Take Off Time:</h6>
          <h6 class="pilot-label-content text-capitalize" v-if="manifest.trip">{{getTime(manifest.trip.takeOffDate,manifest.trip.takeOffTime)}}</h6>
        </div>
      </div>

      <div class="tw-flex-col">
        <h6 class="pilot tw-py-5 lg:tw-py-0" :style="{color: companyData.companyColor}">
          Route
        </h6>
        <div class="tw-flex tw-flex-row tw-items-center lg:tw-mt-10">
          <icons height="80" name="to-and-fro" :color="companyData.companyColor" class="tw-mr-3"/>
          <div class="tw-flex tw-flex-col">
            <div class="tw-flex tw-flex-row ">
              <h6 class="pilot-label tw-mr-5">Departure:</h6>
              <h6 class="pilot-label-content text-capitalize" v-if="manifest.trip">{{manifest.trip.routeDepartureState+ " ( "+ manifest.trip.routeDepartureCity + " - "+manifest.trip.routeDepartureTerminalName+ " )" }}</h6>
            </div>
            <div class="tw-flex tw-flex-row tw-mt-10">
              <h6 class="pilot-label tw-mr-5">Arrival:</h6>
              <h6 class="pilot-label-content text-capitalize" v-if="manifest.trip">{{manifest.trip.routeDestinationState+ " ( "+ manifest.trip.routeDestinationCity + " - "+manifest.trip.routeDestinationTerminalName+ " )" }}</h6>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="tw-flex tw-w-full tw-flex-col">
      <h6 class="tw-py-5 passengers" :style="{color : companyData.companyColor}">Passengers List</h6>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          height="available"
          :items-per-page="-1"
          hide-default-footer
          :items="travellers"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
      >
        <template #header.sno>
          <span class="status">S/N</span>
        </template>
        <template v-slot:[`item.sno`]="{ item }">
          <span class="td-style">{{ item.sno }}</span>
        </template>

        <template #header.firstname>
          <span class="status">First Name</span>
        </template>
        <template v-slot:[`item.firstname`]="{ item }">
          <span class="td-style text-capitalize">{{ item.firstname }}</span>
        </template>
        <template #header.lastname>
          <span class="status">Last Name</span>
        </template>
        <template v-slot:[`item.lastname`]="{ item }">
          <span class="td-style text-capitalize">{{ item.lastname }}</span>
        </template>
        <template #header.phoneNumber>
          <span class="status">Phone Number</span>
        </template>
        <template v-slot:[`item.phoneNumber`]="{ item }">
          <span class="td-style text-capitalize">{{ item.phoneNumber }}</span>
        </template>
        <template #header.address>
          <span class="status">Address</span>
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <span class="td-style text-capitalize">{{ item.address }}</span>
        </template>
<!--        <template #header.gender>-->
<!--          <span class="status">Gender</span>-->
<!--        </template>-->
<!--        <template v-slot:[`item.gender`]="{ item }">-->
<!--          <span class="td-style">{{ item.gender }}</span>-->
<!--        </template>-->
        <template #header.seatno>
          <span class="status">Seat No</span>
        </template>
        <template v-slot:[`item.seatno`]="{ item }">
          <span class="td-style text-capitalize">{{ item.selectedSeat }}</span>
        </template>
        <template #header.bookingid>
          <span class="status ">Booking ID</span>
        </template>
        <template v-slot:[`item.bookingid`]="{ item }">
          <span class="td-style text-uppercase">{{ item.bookingRefId }}</span>
        </template>
        <template #header.nextOfKinFirstName>
          <span class="status">Next Of Kin Names</span>
        </template>
        <template v-slot:[`item.nextOfKinFirstName`]="{ item }">
          <span class="td-style text-capitalize">{{ item.nextOfKinFirstName ? item.nextOfKinFirstName : '-' }} &nbsp;{{ item.nextOfKinLastName ? item.nextOfKinLastName : '-' }}</span>
        </template>
        <template #header.nextOfKinPhoneNumber>
          <span class="status">Next Of Kin No</span>
        </template>
        <template v-slot:[`item.nextOfKinPhoneNumber`]="{ item }">
          <span class="td-style text-capitalize">{{ item.nextOfKinPhoneNumber ? item.nextOfKinPhoneNumber : '-' }}</span>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import dayjs from "dayjs";

export default {
  name: "ManifestContent",
  components: {Icons},
  props:{
    color : [String],
    manifest:{},
    travellers:Array,
  },
  data(){
    return{
      companyData:{},
      headers: [
        {text: "",  value:"sno"},
        {text: "",  value:"firstname"},
        {text: "",  value:"lastname"},
        {text: "",  value:"phoneNumber"},
        {text: "",  value:"address"},
        {text: "",  value:"nextOfKinFirstName"},
        {text: "", value: "nextOfKinPhoneNumber"}
    ],

      not_found: "No Travellers on the manifest",
      manifestId : null,

    }
  },
  methods:{
    getTime(date, time) {
      return dayjs(date + time, "ll").format("LT");
    },

  },
  created() {
    this.companyData = JSON.parse(sessionStorage.getItem("companyData"))

  }
}
</script>

<style scoped lang="scss">

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #4f4f4f;
  padding: 0 0 0 0;
  align-self: center;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
  padding: 0 0 0 0;
  align-self: center;
}

.passengers{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.company-logo{
  width: 80px;
  height: auto;
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
}
</style>